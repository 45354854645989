<template>
  <div class="ud-body">
    <a-card :bordered="true">
      <a-form
        ref="form"
        :rules="rules"
        :label-col="labelCol"
        :model="form"
        :wrapper-col="wrapperCol"
        class="form"
      >
        <a-row>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="模板编号" name="priceCode">
              <a-input
                placeholder="请输入模板编号"
                v-model:value="form.priceCode"
              ></a-input> </a-form-item
          ></a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="模板状态" name="state">
              <a-select v-model:value="form.state" placeholder="请选择模板状态">
                <a-select-option
                  v-for="item in templateStateList"
                  :key="item.dictDataId"
                  :value="item.dictDataName"
                >
                  {{ item.dictDataName }}
                </a-select-option>
              </a-select>
            </a-form-item></a-col
          >
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="所属运营商" name="operatorId">
              <a-select
                v-model:value="form.operatorId"
                placeholder="请选择所属运营商"
                @change="changeOperator"
              >
                <a-select-option
                  v-for="item in operatorList"
                  :key="item.operatorId"
                  :value="item.operatorId"
                >
                  {{ item.operatorName }}
                </a-select-option>
              </a-select>
            </a-form-item></a-col
          >
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="所属租赁点" name="pointId">
              <a-select
                v-model:value="form.pointId"
                placeholder="请选择所属租赁点"
              >
                <a-select-option
                  v-for="item in pointList"
                  :key="item.pointId"
                  :value="item.pointId"
                >
                  {{ item.pointName }}
                </a-select-option>
              </a-select>
            </a-form-item></a-col
          >
        </a-row>
        <a-row v-if="expand">
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="品牌" name="brandName">
              <a-select
                v-model:value="form.brandName"
                placeholder="请选择品牌"
                @change="changeBatteryBrand"
              >
                <a-select-option
                  v-for="item in brandList"
                  :key="item.brandName"
                  :value="item.brandName"
                >
                  {{ item.brandName }}
                </a-select-option>
              </a-select>
            </a-form-item></a-col
          >
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="型号" name="model">
              <a-select v-model:value="form.model" placeholder="请选择型号">
                <a-select-option
                  v-for="item in modelList"
                  :key="item.model"
                  :value="item.model"
                >
                  {{ item.model }}
                </a-select-option>
              </a-select>
            </a-form-item></a-col
          >
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="计费方式" name="priceType">
              <a-select
                v-model:value="form.priceType"
                placeholder="请选择计费方式"
              >
                <a-select-option
                  v-for="item in chargeModeList"
                  :key="item.dictDataCode"
                  :value="item.dictDataCode"
                >
                  {{ item.dictDataName }}
                </a-select-option>
              </a-select>
            </a-form-item></a-col
          >
        </a-row>
        <a-row type="flex" justify="end">
          <a-space class="btn-wrap">
            <a-button type="primary" @click="reload"
              ><i class="iconfont icon-zaitu"></i>查询</a-button
            >
            <a-button @click="reset">
              <i class="iconfont icon-mimazhongzhi"></i>重置</a-button
            >
            <span
              v-if="!expand"
              @click="expandForm"
              style="color: #1890ff; cursor: pointer"
            >
              展开<i class="iconfont icon-xiangxiajiantou"></i>
            </span>
            <span
              v-else
              @click="expandForm"
              style="color: #1890ff; cursor: pointer"
            >
              收起<i class="iconfont icon-xiangshang_jiantou"></i>
            </span>
          </a-space>
        </a-row>
      </a-form>
    </a-card>
    <a-card :bordered="true" class="table">
      <div>
        <ud-pro-table
          ref="table"
          :rowKey="(record) => record.priceId"
          :columns="columns"
          :datasource="datasource"
          @change="handleChange"
          :scroll="{ x: 'max-content' }"
        >
          <template #toolbar>
            <a-space style="margin: 0 20px">
              <a-button type="primary" @click="openEdit()">
                <i class="iconfont icon-xinjian" style="margin: 0 10px 0 0"></i>
                <span>新建计费模板</span>
              </a-button>
            </a-space>
          </template>
          <template #action="{ record }">
            <a-space>
              <a @click="openDetail(record)">详情</a>
              <a @click="openEdit(record)">修改</a>
              <a-divider type="vertical" />
              <a-popconfirm
                title="确定要删除此项目吗?"
                @confirm="remove(record)"
              >
                <a class="ud-text-danger">删除</a>
              </a-popconfirm>
              <a-divider type="vertical" />
              <a-dropdown>
                <a class="ant-dropdown-link" @click.prevent>
                  更多
                  <DownOutlined />
                </a>
                <template #overlay>
                  <a-menu>
                    <a-menu-item key="editPoints" @click="editPoints(record)"
                      >编辑租赁点</a-menu-item
                    >
                    <a-menu-item
                      key="cancellation"
                      @click="cancellation(record)"
                      >作废</a-menu-item
                    >
                    <a-menu-item key="log" @click="checkLog(record)"
                      >操作日志</a-menu-item
                    >
                  </a-menu>
                </template>
              </a-dropdown>
            </a-space>
          </template>
          <!-- <template #enable="{ text, record }">
          <a-switch
            :checked="text === 0"
            @change="(checked) => editEnabled(checked, record)"
          />
        </template> -->
          <template #img="{ record }">
            <img :src="record.pricePic" width="50" height="50" />
          </template>
          <template #customTitle>
            <div>
              所属运营商
              <i
                class="iconfont icon-bukejian"
                style="cursor: pointer"
                v-if="!isShowOperator"
                @click="isShowOperator = true"
              ></i>
              <i
                class="iconfont icon-kejian"
                style="cursor: pointer"
                v-else
                @click="isShowOperator = false"
              ></i>
            </div>
          </template>
          <template #operatorCustom="{ record }">
            <div v-if="!isShowOperator && record.operatorName">
              {{
                record.operatorName.substring(0, 1) +
                new Array(record.operatorName.length).join('*')
              }}
            </div>
            <div v-else>
              {{ record.operatorName }}
            </div>
          </template>
        </ud-pro-table>
      </div>
      <a-modal
        v-model:visible="visiblePoints"
        title="编辑租赁点"
        @ok="handlePointsOk"
        centered
      >
        <a-form
          ref="pointsForm"
          :model="pointsForm"
          :rules="pointsRules"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
        >
          <a-form-item label="所属租赁点" name="pointIds">
            <a-select
              v-model:value="pointsForm.pointIds"
              palceholder="请选择所属租赁点"
              mode="multiple"
              @change="pointsChange"
            >
              <a-select-option
                v-for="item in pointList"
                :key="item.pointId"
                :value="item.pointId"
              >
                {{ item.pointName }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-form>
      </a-modal>
      <a-modal
        v-model:visible="visibleCancell"
        title="作废"
        @ok="handleCancellOk"
        centered
      >
        <div style="text-align: center">
          <img src="../../../assets/zf.png" alt="" />
          <div>
            <span style="font-weight: bolder; font-size: 16px">提示:</span
            ><span style="font-size: 16px">确认该计费模板作废嘛?</span>
          </div>
        </div>
      </a-modal>
      <a-modal
        v-model:visible="visibleLog"
        title="操作日志"
        @ok="handleLogOk"
        centered
        :width="800"
        :footer="null"
      >
        <!-- :scroll="{ y: 500 }"
          :pagination="pagination" -->
        <ud-pro-table
          class="logForm"
          row-key="billId"
          :datasource="logData"
          :columns="logColumns"
          bordered
          :scroll="{ x: 'max-content' }"
        >
        </ud-pro-table>
      </a-modal>
      <operation-detail
        v-model:visible="showDetail"
        :data="current || {}"
      ></operation-detail>
      <operation-edit
        v-model:visible="showEdit"
        :data="current"
        @done="reload"
      />
    </a-card>
  </div>
</template>

<script>
import operationDetail from './operationDetail.vue'
import operationEdit from './operationEdit.vue'
import * as priceApi from '@/api/rnt/price.js'
// import * as batteryApi from '@/api/rnt/battery'
import { DownOutlined } from '@ant-design/icons-vue'
import * as batteryModelApi from '@/api/rnt/batteryModel'
import * as operatorApi from '@/api/rnt/operator'
import * as pointApi from '@/api/rnt/point.js'
export default {
  components: {
    DownOutlined,
    operationDetail,
    operationEdit
  },
  data() {
    return {
      logData: [
        // {
        //   actionContent: '充值金额380.0元',
        //   actionPerson: 'guowenjun',
        //   actionTime: '2022-01-12 15:46:38'
        // },
        // {
        //   actionContent: '充值押金4000.0元',
        //   actionPerson: 'guowenjun',
        //   actionTime: '2022-01-12 15:46:38'
        // },
        // {
        //   actionContent: '充值金额380.0元',
        //   actionPerson: 'guowenjun',
        //   actionTime: '2022-01-12 15:46:38'
        // },
        // {
        //   actionContent: '充值金额380.0元',
        //   actionPerson: 'guowenjun',
        //   actionTime: '2022-01-12 15:46:38'
        // }
      ],
      pointsRules: {},
      logColumns: [
        {
          title: '序号',
          dataIndex: 'index',
          width: 65,
          // align: 'center',
          customRender: ({ text, record, index }) => index + 1
        },
        {
          title: '操作内容',
          // align: 'center',
          dataIndex: 'billMsg'
        },
        {
          title: '操作人',
          // align: 'center',
          dataIndex: 'creater'
        },
        {
          title: '操作时间',
          align: 'center',
          width: 200,
          dataIndex: 'updateTime'
        }
      ],
      chargeModeList: [],
      templateStateList: [],
      // 电池品牌
      brandList: [],
      // 型号
      modelList: [],
      operatorList: [],
      pointList: [],
      labelCol: { md: { span: 6 }, sm: { span: 24 } },
      wrapperCol: { md: { span: 16 }, sm: { span: 24 } },
      showDetail: false,
      showEdit: false,
      current: null,
      form: {
        // number: '',
        // model: '',
        // brand: '',
        // point: '',
        // operator: '',
        // state: '',
        // way:''
      },
      rules: {},
      expand: false,
      isShowOperator: false,
      columns: [
        {
          title: '序号',
          width: 48,
          // align: 'center',
          customRender: ({ text, record, index }) => index + 1
        },
        {
          title: '名称',
          dataIndex: 'priceName'
        },
        {
          title: '图片',
          dataIndex: 'pricePic',
          slots: { customRender: 'img' }
        },
        {
          title: '模板编号',
          // width: 170,
          // align: 'center',
          dataIndex: 'priceCode'
        },
        {
          title: '模板状态',
          // width: 100,
          // align: 'center',
          dataIndex: 'state'
          // width: 90,
          // align: 'center',
          // slots: { customRender: 'enable' }
        },
        {
          title: '租金(元)',
          // width: 85,
          // align: 'center',
          dataIndex: 'rent'
        },
        {
          title: '押金(元)',
          // width: 85,
          // align: 'center',
          dataIndex: 'deposit'
        },
        {
          title: '已签合同押金(元)',
          // width: 85,
          // align: 'center',
          dataIndex: 'contractedDeposit'
        },
        {
          title: '丢失保障服务费(元)',
          // width: 160,
          // align: 'center',
          dataIndex: 'lossFee'
        },
        {
          title: '折扣系数',
          // width: 90,
          // align: 'center',
          dataIndex: 'discountFactor'
        },
        {
          title: '计费周期',
          // width: 90,
          // align: 'center',
          dataIndex: 'cycle'
        },
        {
          title: '次数',
          // width: 65,
          // align: 'center',
          dataIndex: 'count'
        },
        {
          title: '每次价格',
          // width: 65,
          // align: 'center',
          dataIndex: 'perPrice'
        },
        {
          title: '所属品牌',
          // width: 90,
          // align: 'center',
          dataIndex: 'brandName'
        },
        {
          title: '所属型号',
          // width: 90,
          // align: 'center',
          dataIndex: 'model'
        },
        {
          // width: 175,
          // align: 'center',
          dataIndex: 'operatorName',
          slots: {
            title: 'customTitle',
            customRender: 'operatorCustom'
          }
        },
        {
          title: '所属租赁点',
          // width: 175,
          // align: 'center',
          dataIndex: 'pointNames'
        },
        {
          title: '创建人',
          // width: 120,
          // align: 'center',
          dataIndex: 'creater'
        },
        {
          title: '创建时间',
          // width: 180,
          // align: 'center',
          dataIndex: 'createTime'
        },
        {
          title: '操作',
          width: 200,
          dataIndex: 'action',
          align: 'center',
          slots: {
            customRender: 'action'
          }
        }
      ],
      visiblePoints: false, // 打开余额充值对话框
      visibleCancell: false,
      visibleLog: false,
      pointsForm: {},
      cancellForm: {}
    }
  },
  mounted() {
    this.queryOperater()
    this.queryPoint()
    this.queryTemplateList()
    this.querychargeModeList()
    this.queryBrandList()
    this.queryModelList()
  },
  methods: {
    // 作废模板
    cancellation(row) {
      this.visibleCancell = true
      this.cancellForm = row
    },
    // 编辑网点
    editPoints(record) {
      this.visiblePoints = true
      this.pointsForm.priceId = record.priceId
      if (record.pointIds) {
        if (typeof record.pointIds === 'string') {
          this.pointsForm.pointIds = record.pointIds.split(',').map((item) => {
            return parseInt(item)
          })
        }
      } else {
        this.pointsForm.pointIds = []
      }
    },
    // 查看操作日志
    checkLog(record) {
      this.visibleLog = true
      this.logForm.userId = record.userId
      // userBalanceApi
      //   .page({
      //     ...this.logForm
      //   })
      //   .then((res) => {
      //     if (res.code === 0) {
      //       this.logData = res.data
      //     } else {
      //       this.$message.error(res.msg)
      //     }
      //   })
      //   .catch((e) => {
      //     this.$message.error(e.message)
      //   })
    },
    handlePointsOk() {
      const data = Object.assign({}, this.pointsForm, {
        pointIds: this.pointsForm.pointIds.join(',')
      })
      priceApi
        .save(data, true)
        .then((res) => {
          if (res.code === 0) {
            this.visiblePoints = false
            this.reload()
            this.$message.success(res.msg)
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    //  /* 修改模板状态 */
    // editEnabled(checked, row) {
    //   const data = {
    //     enable: checked ? 0 : 1,
    //     batteryId: row.batteryId
    //   }
    //   batteryApi
    //     .enable(data)
    //     .then((res) => {
    //       if (res.code === 0) {
    //         row.enable = checked ? 0 : 1
    //         this.$message.success(res.msg)
    //       } else {
    //         this.$message.error(res.msg)
    //       }
    //     })
    //     .catch((e) => {
    //       this.$message.error(e.message)
    //     })
    // },
    // 获取计费方式列表
    querychargeModeList() {
      priceApi
        .chargeMode()
        .then((res) => {
          if (res.code === 0) {
            this.chargeModeList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    queryBrandList() {
      batteryModelApi
        .brand()
        .then((res) => {
          if (res.code === 0) {
            this.brandList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    queryModelList() {
      batteryModelApi
        .model()
        .then((res) => {
          if (res.code === 0) {
            this.modelList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    // 获取模板状态列表
    queryTemplateList() {
      priceApi
        .templateState()
        .then((res) => {
          if (res.code === 0) {
            this.templateStateList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    // 运营商改变获取对应品牌列表
    changeOperator(value) {
      this.form.brandName = null
      this.form.model = null
      batteryModelApi
        .brand({ operatorId: value })
        .then((res) => {
          if (res.code === 0) {
            this.brandList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    // 电池品牌改变获取对应型号
    changeBatteryBrand(value) {
      this.form.model = null
      batteryModelApi
        .page({ brandName: value, operatorId: this.form.operatorId })
        .then((res) => {
          if (res.code === 0) {
            this.modelList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    /* 查询所属租赁点 */
    queryPoint() {
      pointApi
        .page()
        .then((res) => {
          if (res.code === 0) {
            this.pointList = res.data
            // res.data.forEach((item) => {
            //   const pointId = { value: item.pointId, label: item.pointName }
            //   this.pointsOption.push(pointId)
            // })
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    /* 查询运营商 */
    queryOperater() {
      operatorApi
        .page()
        .then((res) => {
          if (res.code === 0) {
            this.operatorList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    // 获取表格数据
    datasource(option, callback) {
      priceApi
        .page({
          ...this.form,
          page: option.page,
          limit: option.limit
        })
        .then((res) => {
          if (res.code === 0) {
            callback(res.data, res.count)
          } else {
            callback(res.msg)
          }
        })
        .catch((e) => {
          callback(e.message)
        })
    },
    openDetail(row) {
      this.current = row
      this.showDetail = true
    },
    openEdit(row) {
      console.log(row)
      this.current = row
      this.showEdit = true
    },
    /* 删除单个 */
    remove(row) {
      const hide = this.$message.loading('请求中..', 0)
      priceApi
        .deleteById(row.priceId)
        .then((res) => {
          hide()
          if (res.code === 0) {
            this.$message.success(res.msg)
            this.reload()
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          hide()
          this.$message.error(e.message)
        })
    },
    /* 刷新表格 */
    reload() {
      // this.selection = []
      this.$refs.table.reload({ page: 1 })
    },
    /* 重置搜索 */
    reset() {
      this.form = {}
      this.$nextTick(() => {
        this.reload()
      })
    },
    // 新增计费模板确定按钮回调事件
    handleBillingOk() {
      this.$refs.billingForm.validate().then((res) => {
        console.log(res)
        this.$message.success('添加计费模板成功！')
        this.visible = false
      })
    },
    // 是否展开表单
    expandForm() {
      this.expand = !this.expand
    },
    // 表格改变
    handleChange() {}
  }
}
</script>
<style lang='less' scoped>
.form {
  .btn-wrap {
    padding-right: 20px;
    .iconfont {
      margin-right: 5px;
    }
  }
}
.table {
  margin: 10px 0;
}
.logForm {
  :deep(.ud-table-tool) {
    display: none;
  }
}
</style>
